@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Icons';
    src: url('./assets/fonts/icons/cardif-icons.ttf') format('truetype');
  }

  @font-face {
    font-family: "Open Sans";
    src: url("../public/fonts/open-sans-regular.ttf");
  }

  @font-face {
    font-family: "Ubuntu";
    src: url("../public/fonts/ubuntu-regular.ttf");
  }

  .img-bubble {
    background-repeat: no-repeat;
    background-size: contain;
    z-index:-1;
  }

  .img-bubble-left {
    background-image: url('./assets/Images/bubbles-left.svg');
  }
  
  .img-bubble-right {
    background-image: url('./assets/Images/bubbles-right.svg');
    background-position: right center;
  }

  html {
    font-family: 'Open Sans';
  }

}
