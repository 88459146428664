input[type="radio"]:checked,
input[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
input[type="radio"]:checked + span,
input[type="radio"]:not(:checked) + span {
    position: relative;
    padding-left: 46px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    color: #747474;
    font-family: 'Ubuntu';
}
input[type="radio"] + span:before {
    content: '';
    position: absolute;
    left: 0;
    top: -2px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
}
input[type="radio"]:checked + span:before {
  border: 2px solid #3A913F;
}
input[type="radio"]:not(:checked) + span:before {
    border: 2px solid #979797;
}
input[type="radio"]:checked + span:after,
input[type="radio"]:not(:checked) + span:after {
    content: '';
    width: 14px;
    height: 14px;
    background: #3A913F;
    position: absolute;
    top: 4px;
    left: 6px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
 }
 input[type="radio"]:not(:checked) + span:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
 }
 input[type="radio"]:checked + span:after {
     opacity: 1;
     -webkit-transform: scale(1);
     transform: scale(1);
 }
