.after-common::after {
  position: absolute;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
  pointer-events: none;
}

.after-arrow::after {
  content: '';
  border-top: 8px dashed #58585a;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-radius: 3px;
}

.after-check::after {
  content: '';
  font-size: 10px;
}

select {
  background: #fff;
}
